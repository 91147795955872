<template>
    <section id="badges">
        <a href="https://holopin.io/@luizhlelis">
            <v-img src="https://holopin.me/luizhlelis" alt="@luizhlelis's Holopin board"  />
        </a>
    </section>
</template>

<script>
export default {
    name: 'Badges'
}
</script>